import { lory } from '@rsm/allfarblori';
import iconButton from 'components/atoms/icon-button/icon-button';
import { MEDIA_QUERIES } from 'javascripts/constants';

export default class TeaserSlider {
  constructor($el) {
    this.$el = $el;
    this.$slidesFrame = this.$el.querySelector('.teaser-group__slider-frame');
    this.$slidesContainer = this.$el.querySelector('.teaser-group__inner');
    this.$slides = this.$el.querySelectorAll('.teaser-group__content');
    this.$sliderHasAutoplay = this.$el.dataset.autoplay ? this.$el.dataset.autoplay : false;

    // Don't init slider, if only one x slides exists (1-3 depending on viewport)
    if (this.$slides.length <= this.mediaQueryCheck()) {
      return;
    }

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.initControls();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('teaser-group--slider-initialized');
      //  init slider autoplay
      if (this.$sliderHasAutoplay !== 'false') {
        this.initTriggerAutorunSlider();
      }
    });

    this.lory = lory(this.$el, {
      slidesToScroll: 1,
      rewind: true,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      infinite: 0,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: 'teaser-group__slider-frame',
      classNameSlideContainer: 'teaser-group__inner',
      classNamePrevCtrl: 'teaser-group__slider-control--prev',
      classNameNextCtrl: 'teaser-group__slider-control--next',
      classNameDisabledPrevCtrl: 'teaser-group__slider-control--disabled',
      classNameDisabledNextCtrl: 'teaser-group__slider-control--disabled',
    });
  }

  initControls() {
    this.$controlPrev = iconButton({
      icon: 'arrow-left',
      title: 'Vorheriger Slide',
      medium: true,
      classes: ['teaser-group__slider-control', 'teaser-group__slider-control--prev'],
    });

    this.$controlNext = iconButton({
      icon: 'arrow-right',
      title: 'Nächster Slide',
      medium: true,
      classes: ['teaser-group__slider-control', 'teaser-group__slider-control--next'],
    });

    const $controls = document.createElement('div');
    $controls.setAttribute('class', 'teaser-group__slider-controls');

    $controls.appendChild(this.$controlNext);
    $controls.appendChild(this.$controlPrev);

    this.$el.appendChild($controls);
  }

  mediaQueryCheck() {
    const mq = [
      window.matchMedia(MEDIA_QUERIES.s),
      window.matchMedia(MEDIA_QUERIES.m),
      window.matchMedia(MEDIA_QUERIES.l),
    ];
    let maxSlides = 1;

    if (mq[0].matches) maxSlides = 1;
    if (mq[1].matches) maxSlides = 2;
    if (mq[2].matches) maxSlides = 3;

    return maxSlides;
  }

  //  autoplay slider - cancel autoplay on prev/next click
  initTriggerAutorunSlider() {
    const btnNext = this.$el.querySelector('.teaser-group__slider-control');
    const btnPrev = this.$el.querySelector('.teaser-group__slider-control--prev');
    const autoplaySpeed = this.$el.dataset.autoplaySpeed ? this.$el.dataset.autoplaySpeed : 3000;
    let intervalId;

    if (btnNext !== null) {
      // click handler
      const handleClick = () => {
        // deactivate interval
        clearInterval(intervalId);
      };

      // Click event listener to stop autoplay
      btnNext.addEventListener('click', handleClick);
      btnPrev.addEventListener('click', handleClick);

      // start interval
      intervalId = setInterval(() => {
        // autoplay to next slide
        this.lory.next();
      }, parseInt(autoplaySpeed, 10));
    }
  }
}

if (typeof (document.querySelector('.teaser-group--slider')) !== 'undefined'
  && document.querySelector('.teaser-group--slider') != null) {
  document.querySelectorAll('.teaser-group--slider').forEach($slider => new TeaserSlider($slider));
}

import { lory } from '@rsm/allfarblori';
import iconButton from 'components/atoms/icon-button/icon-button';
// import { t } from '../../../javascripts/utils/withTranslation';

export default class Slider {
  constructor($el) {
    this.$el = $el;
    this.$slidesFrame = this.$el.querySelector('.breaker-image__slider-slides-frame');
    this.$slidesContainer = this.$el.querySelector('.breaker-image__slider-slides');
    this.$slides = this.$el.querySelectorAll('.breaker-image__slider-slide');

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.initControls();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('breaker-image--slider-initialized');
    });

    this.lory = lory(this.$el, {
      rewind: true,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      infinite: 0,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: 'breaker-image__slider-slides-frame',
      classNameSlideContainer: 'breaker-image__slider-slides',
      classNamePrevCtrl: 'breaker-image__slider-control--prev',
      classNameNextCtrl: 'breaker-image__slider-control--next',
      classNameDisabledPrevCtrl: 'breaker-image__slider-control--disabled',
      classNameDisabledNextCtrl: 'breaker-image__slider-control--disabled',
    });
  }

  getMediaHeight() {
    // Get defined height of Slider Images
    // (as represented by height of first contained .figure__media)
    return this.$el.querySelector('.figure__media').getBoundingClientRect().height;
  }

  initControls() {
    this.$controlPrev = iconButton({
      icon: 'arrow-left',
      title: 'Vorheriger Slide',
      medium: true,
      classes: ['breaker-image__slider-control', 'breaker-image__slider-control--prev'],
    });

    this.$controlNext = iconButton({
      icon: 'arrow-right',
      title: 'Nächster Slide',
      medium: true,
      classes: ['breaker-image__slider-control', 'breaker-image__slider-control--next'],
    });

    const $controls = document.createElement('div');
    $controls.setAttribute('class', 'breaker-image__slider-controls');

    const controlsPosition = () => {
      $controls.style.top = `${this.getMediaHeight()}px`;
    };

    this.$el.addEventListener('after.lory.init', controlsPosition);
    this.$el.addEventListener('on.lory.resize', controlsPosition);

    $controls.appendChild(this.$controlNext);
    $controls.appendChild(this.$controlPrev);

    this.$el.appendChild($controls);
  }
}

document.querySelectorAll('.breaker-image--slider').forEach($slider => new Slider($slider));

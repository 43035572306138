import Cookies from 'js-cookie';

import {
  openCookieMsg,
  marketingAccepted,
  youtubeAccepted,
  COOKIESTATUS,
  COOKIES,
} from 'components/organisms/cookie-msg/cookie-msg';
import h from 'hyperscript';
import getVideoId from 'get-video-id';

class Figure {
  constructor($el) {
    // Elementsfig
    this.$el = $el;
    this.$media = this.$el.querySelector('.figure__media');
    this.$videoPlay = this.$el.querySelector('.figure__play');
    this.$acceptCookiesButton = this.$el.querySelector('.figure__button-accept');

    // Add events
    if (this.$videoPlay) {
      this.$videoPlay.addEventListener('click', this.onVideoClick.bind(this));
    }

    if (this.$acceptCookiesButton) {
      this.$acceptCookiesButton.addEventListener('click', (event) => {
        event.preventDefault();

        Cookies.set(COOKIES.YOUTUBE, COOKIESTATUS.ACCEPT, { expires: 365, path: '/' });

        window.location.reload();
      });
    }

    if (marketingAccepted || youtubeAccepted) {
      this.renderVideo();
    }

    document.addEventListener('cookieSettingsEvent', (event) => {
      if (event.detail && event.detail[COOKIES.YOUTUBE] === COOKIESTATUS.ACCEPT) {
        this.renderVideo();
      }
    });
  }

  onVideoClick(event) {
    event.preventDefault();

    const { id, service } = getVideoId(this.$videoPlay.href);

    if (id && service === 'youtube') {
      if (marketingAccepted || youtubeAccepted) {
        this.renderVideo();
      } else {
        openCookieMsg();
      }
    }
  }

  renderVideo() {
    if (this.$videoPlay) {
      const { id } = getVideoId(this.$videoPlay.href);

      if (id) {
        // Construct Youtube iframe player, add rel=0 to disable related videos
        const $iframe = h(
          '.figure__video-wrapper',
          h('iframe.figure__video', {
            src: `https://www.youtube.com/embed/${id}?rel=0`,
            allowFullscreen: true,
            msAllowFullscreen: true,
            webkitAllowFullscreen: true,
            mozAllowFullscreen: true,
          }),
        );

        // Replace with iframe
        this.$videoPlay.parentNode.className += ' figure__media--initialize-video';
        this.$media.insertAdjacentElement('beforeend', $iframe);
      }
    }
  }

  getMediaType() {
    return this.$el.querySelector('.figure__play') ? 'video' : 'image';
  }
}

document.querySelectorAll('.js-figure').forEach($el => new Figure($el));

import Cookies from 'js-cookie';
import CustomEvent from 'custom-event';
import accordionToggle from '../accordion/accordion';

export const COOKIESTATUS = {
  ACCEPT: 'accept',
  DECLINE: 'decline',
  NOTSET: 'notset',
};

export const COOKIES = {
  MARKETING: `${window.degewoblog.COOKIE_TENANT}-cookie-marketing`,
  YOUTUBE: `${window.degewoblog.COOKIE_TENANT}-cookie-youtube`,
  CONSENT: `${window.degewoblog.COOKIE_TENANT}-cookie-consent`,
};

export const marketingDeclined = (
  // eslint-disable-next-line max-len
  [COOKIESTATUS.DECLINE, COOKIESTATUS.NOTSET].indexOf(Cookies.get(COOKIES.MARKETING)) > -1 || !Cookies.get(COOKIES.MARKETING)
);

export const marketingAccepted = Cookies.get(COOKIES.MARKETING) === COOKIESTATUS.ACCEPT;
export const youtubeAccepted = Cookies.get(COOKIES.YOUTUBE) === COOKIESTATUS.ACCEPT;

function cookieMsgCollapsible($el) {
  const $content = $el.getAttribute('aria-controls');

  if ($content) {
    const $inner = $el.querySelector('.cookie-msg__toggle-text');

    $el.addEventListener('click', (event) => {
      if (event && event.currentTarget) {
        const isExpanded = event.currentTarget.getAttribute('aria-expanded') === 'true';
        $el.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
        document.getElementById($content).setAttribute('aria-hidden', isExpanded ? 'true' : 'false');

        if ($inner) {
          const showText = $inner.getAttribute('data-text-show');
          const hideText = $inner.getAttribute('data-text-hide');
          $inner.innerHTML = isExpanded ? showText : hideText;
        }
      }
    });
  }
}

export default class CookieMsg {
  constructor() {
    if (Cookies.get(COOKIES.CONSENT) !== 'true') this.showMessage();
  }

  setConsentCookie() {
    Cookies.set(COOKIES.CONSENT, true, { expires: 365, path: '/' });
  }

  setCookieChoice(choices) {
    if (choices) {
      Object.keys(choices).forEach((key) => {
        Cookies.set(key, choices[key], { expires: 365, path: '/' });
      });
    }
  }

  insertAfter(newNode, referenceNode) {
    return referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  showMessage() {
    const $template = document.getElementById('tpl-cookie-msg');
    const markup = $template.innerHTML;

    const placeholder = document.createElement('div');
    placeholder.innerHTML = markup;
    const $node = placeholder.firstElementChild;

    const $newEl = this.insertAfter($node, $template);
    $newEl.classList.add('is-fixed');

    const $closeButton = document.getElementById('cookie-consent-submit');
    const $acceptButton = document.getElementById('cookie-consent-submit-all');
    const $form = document.getElementById('cookie-consent-form');
    const $marketingSub = document.querySelectorAll('.cookie-msg__marketing');

    if ($form) {
      const $marketing = document.getElementById(COOKIES.MARKETING);

      const marketingSubInputs = [
        document.getElementById(COOKIES.YOUTUBE),
      ];

      if ($marketing) {
        const initialChoices = {};

        [$marketing, ...marketingSubInputs].forEach(($input) => {
          if ($input && Cookies.get($input.getAttribute('id')) === COOKIESTATUS.ACCEPT) {
            $input.setAttribute('checked', true);
            // eslint-disable-next-line no-param-reassign
            $input.checked = true;

            if ($marketingSub && $input.getAttribute('id') === COOKIES.MARKETING) {
              $marketingSub.forEach(($subItem) => {
                // eslint-disable-next-line no-param-reassign
                $subItem.checked = true;
              });
            }
          }

          if ($input && !Cookies.get($input.getAttribute('id'))) {
            initialChoices[$input.getAttribute('id')] = COOKIESTATUS.NOTSET;
          }
        });

        const allMarketingSubsChecked = marketingSubInputs.every(
          $subItem => $subItem && $subItem.checked,
        );

        $marketing.setAttribute('checked', allMarketingSubsChecked);
        $marketing.checked = allMarketingSubsChecked;

        this.setCookieChoice(initialChoices);
      }

      $marketing.addEventListener('click', () => {
        $marketingSub.forEach(($subItem) => {
          if ($marketing.checked) {
            // eslint-disable-next-line no-param-reassign
            $subItem.checked = true;
          } else {
            // eslint-disable-next-line no-param-reassign
            $subItem.checked = false;
            // $subItem.removeAttribute('checked');
          }
        });
      });

      $marketingSub.forEach(($subItem) => {
        $subItem.addEventListener('click', () => {
          if ($marketingSub.length === document.querySelectorAll('.cookie-msg__marketing:checked').length) {
            // eslint-disable-next-line no-param-reassign
            $marketing.checked = true;
          } else {
            // eslint-disable-next-line no-param-reassign
            $marketing.checked = false;
          }
        });
      });

      $closeButton.addEventListener('click', (event) => {
        event.preventDefault();
        if ($marketing) {
          const choices = {};
          [$marketing, ...marketingSubInputs].forEach(($input) => {
            if ($input) {
              choices[$input.getAttribute('id')] = $input.checked ? COOKIESTATUS.ACCEPT : COOKIESTATUS.DECLINE;
            }
          });
          this.setCookieChoice(choices);

          const cookieEvent = new CustomEvent('cookieSettingsEvent', {
            bubbles: true,
            detail: choices,
          });

          document.dispatchEvent(cookieEvent);
        }
        this.setConsentCookie();
        $newEl.remove();
        window.location.reload();
      });

      $acceptButton.addEventListener('click', (event) => {
        event.preventDefault();

        const choices = {};

        [$marketing, ...marketingSubInputs].forEach(($input) => {
          if ($input) {
            choices[$input.getAttribute('id')] = COOKIESTATUS.ACCEPT;
          }
        });

        this.setCookieChoice(choices);

        const cookieEvent = new CustomEvent('cookieSettingsEvent', {
          bubbles: true,
          detail: choices,
        });

        document.dispatchEvent(cookieEvent);

        this.setConsentCookie();
        $newEl.remove();
        window.location.reload();
      });
    }

    $newEl.querySelectorAll('.js-toggle-accordion').forEach(($toggle) => {
      $toggle.addEventListener('click', (toggleEvent) => {
        const $target = toggleEvent.currentTarget;

        if ($target) {
          accordionToggle($toggle);
        }
      });
    });

    if (document.querySelector('.cookie-msg__toggle')) {
      document.querySelectorAll('.cookie-msg__toggle').forEach($el => cookieMsgCollapsible($el));
    }
  }
}

export function openCookieMsg() {
  Cookies.set(COOKIES.CONSENT, false, { expires: 365, path: '/' });

  if (document.querySelectorAll('.cookie-msg').length === 0) {
    // eslint-disable-next-line no-new
    new CookieMsg();
  }
}

if (document.querySelectorAll('#tpl-cookie-msg').length) {
  // eslint-disable-next-line no-new
  new CookieMsg();
}

if (document.querySelectorAll('.cookie-msg-open').length) {
  document.querySelectorAll('.cookie-msg-open').addEventListener('click', () => openCookieMsg());
}

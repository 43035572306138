import { MEDIA_QUERIES } from 'javascripts/constants';
import HeaderToggle from './HeaderToggle';

document.querySelectorAll('.header__toggle').forEach(($toggle) => {
  const headerToggle = new HeaderToggle($toggle);

  // Init media query
  const mql = window.matchMedia(MEDIA_QUERIES.l);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;

    if (isDesktop) {
      headerToggle.deinit();
    } else {
      headerToggle.init();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});

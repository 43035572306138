export default class IconButtonGroupToggle {
  constructor($toggle) {
    this.$toggle = $toggle;
    this.$toggleParent = $toggle.parentNode;

    // Get list
    const listId = $toggle.getAttribute('aria-controls');
    this.$list = document.getElementById(listId);

    // Bind event listeners
    this.onClickBinded = this.onClick.bind(this);
    this.onOutsideClickBinded = this.onOutsideClick.bind(this);
    this.onMousedownBinded = this.onMousedown.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);
  }

  init() {
    this.$toggle.setAttribute('aria-expanded', false);

    // Add event listener
    this.$toggle.addEventListener('mousedown', this.onMousedownBinded);
    this.$toggle.addEventListener('click', this.onClickBinded);

    // Set state
    this.isOpen = false;
    this.inited = true;
  }

  deinit() {
    // Don't run deinit, if never inited
    if (!this.inited) {
      return;
    }

    this.$toggle.removeAttribute('aria-expanded');

    // Set state
    this.isOpen = false;
    this.inited = false;
  }

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.$toggle.setAttribute('aria-expanded', 'true');

    // Add tabindex 0 to list
    this.$list.setAttribute('tabindex', 0);

    // Set focus to list
    this.$list.focus();

    // Handle outside click
    document.addEventListener('click', this.onOutsideClickBinded);

    // Add event listeners
    document.addEventListener('keydown', this.onKeydownBinded);

    this.isOpen = true;
  }

  close() {
    this.$toggle.setAttribute('aria-expanded', 'false');

    // Remove tabindex 0 from list
    this.$list.removeAttribute('tabindex');

    // Remove outside click
    document.removeEventListener('click', this.onOutsideClickBinded);

    // Remove event listeners
    document.removeEventListener('keydown', this.onKeydownBinded);

    this.isOpen = false;
  }

  onMousedown(event) {
    event.preventDefault();
  }

  onClick(event) {
    event.preventDefault();
    this.toggle();
  }

  onOutsideClick(event) {
    const $target = event.target;

    if (this.$toggleParent.contains($target)) {
      return;
    }

    this.close();
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.close();
    }
  }
}

import BeforeAfter from 'before-after';

// Extend the original script to fix baked-in Z-Index setting from 1 to 2
class RsmBeforeAfter extends BeforeAfter {
  create(callback) {
    // Check if item active exist
    if (this.itemActive === null) {
      // eslint-disable-next-line no-console
      console.warn('BeforeAfter::Item active is missing');
    } else {
      this.detectOrientation();
      this.itemActive.style.zIndex = 2;

      // Build the cursor if option is enabled
      if (this.options.cursor) {
        this.updateCursor();
      }

      // Check images loaded before wrap it
      this.checkImagesLoaded(() => {
        // eslint-disable-next-line radix
        this.heightElement = parseInt(this.options.element.offsetHeight);
        // eslint-disable-next-line radix
        this.widthElement = parseInt(this.options.element.offsetWidth);
        this.addImageWrapper();
      });

      this.addEvents();

      // Excecute the callback function if it is available
      if (typeof callback === 'function') {
        callback();
      }
    }
  }
}

document.querySelectorAll('.before-after-slider').forEach(($beforeafter) => {
  $beforeafter.querySelectorAll('.before-after-slider__items').forEach(($list) => {
    const $slides = $list.querySelectorAll('.before-after-slider__item');
    $slides[0].classList.add('before-after-slider__item-active');
  });

  const beforeAfterItem = new RsmBeforeAfter({
    element: $beforeafter.querySelector('.before-after-slider__items'),
    selectors: {
      item: '.before-after-slider__item',
      itemActive: '.before-after-slider__item-active',
      cursor: '.before-after-slider__cursor',
      imageWrapper: '.before-after-slider__wrapperImage',
    },
  });

  beforeAfterItem.create(() => {
    beforeAfterItem.goTo(50);
  });

  function updateCursor() {
    const Beforecursor = $beforeafter.querySelector('.before-after-slider__cursor');
    Beforecursor.style.backgroundColor = 'white';
    Beforecursor.style.width = '3px';
    Beforecursor.style.overflow = 'visible';
  }
  updateCursor();

  // eslint-disable-next-line no-param-reassign
  $beforeafter.querySelector('.before-after-slider__cursor').innerHTML = '<div class="before-after-slider__move"><span class="before-after-slider__move-label">Verschieben</span></div>';

  // eslint-disable-next-line no-param-reassign
  $beforeafter.querySelector('.before-after-slider__items').onmousemove = function mousemove() {
    updateCursor();
  };

  // eslint-disable-next-line no-param-reassign
  $beforeafter.querySelector('.before-after-slider__items').ontouchmove = function touchmove() {
    updateCursor();
  };
});

import Cookies from 'js-cookie';
import { openCookieMsg, COOKIES } from '../cookie-msg/cookie-msg';

export default class CookieFingerprint {
  constructor($el) {
    this.$el = $el;
    this.$toggleButton = this.$el.querySelector('.cookie-fingerprint__button');

    this.init();
  }

  init() {
    const cookieConsent = Cookies.get(COOKIES.CONSENT);

    if (cookieConsent && cookieConsent !== 'false') {
      this.$el.classList.add('cookie-fingerprint--visible');
    }

    this.$toggleButton.addEventListener('click', (event) => {
      event.preventDefault();

      openCookieMsg();

      this.$el.classList.remove('cookie-fingerprint--visible');
    });
  }
}

document.querySelectorAll('.js-cookie-fingerprint').forEach(
  $el => new CookieFingerprint($el),
);

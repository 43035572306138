const environment = window.degewoblog || {};

export const ASSET_BASE_URL = environment.ASSET_BASE_URL || '/';

export const ICON_SPRITE_URL = environment.ICON_SPRITE_URL || '/media/icons/icons.svg';

export const TITLE = environment.TITLE || 'degewo - Unternehmensblog - Karriereportal';

export const LANG = document.documentElement.lang || 'de';

export const MAPBOX_KEY = environment.MAPBOX_KEY || null;

export const PRIVACY_LINK = environment.PRIVACY_LINK || '';

export const MEDIA_QUERIES = {
  s: '(min-width: 0px)',
  m: '(min-width: 768px)',
  l: '(min-width: 1025px)',
  xl: '(min-width: 1440px)',
};

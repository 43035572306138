import AudioSlider from './audio-slider';

// Formats a given time in seconds to MM:SS
function seconds2Time(totalSeconds) {
  const seconds = Math.floor(totalSeconds) % 60;
  const minutes = Math.floor(totalSeconds / 60) % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export default class AudioPlayer {
  constructor($element, options = {}) {
    this.$element = $element;

    this.options = {
      classes: {
        player: 'audio__player',
        playpause: 'audio__button--playpause',
        songslider: 'audio__slider--songtrack',
        volumeslider: 'audio__slider--volume',
        currentTimeText: 'audio__time--current',
        durationTimeText: 'audio__time--duration',
        mutebutton: 'audio__button--mute',
        initModifier: 'audio--initialized',
        loadingModifier: 'audio--loading',
      },
      intialized: false,
      ...options,
    };

    this.init();
    // this.initListeners();
  }

  asyncInit() {
    // For the player to be initialized, an audio file must have been loaded.
    if (!this.options.intialized && this.$player.duration) {
      // Register that the audio player component is now initialized
      // which means it is ready to play back
      this.options.intialized = true;
      this.$element.classList.remove(this.options.classes.loadingModifier);
      this.$element.classList.add(this.options.classes.initModifier);
      // initialy display the audio duration
      this.timeupdate();
      this.$durationText.innerHTML = seconds2Time(this.$player.duration);
    }
  }

  init() {
    // Register that javascript is about to handle the audio player
    this.$element.classList.add(this.options.classes.loadingModifier);
    // Find player and control elements
    this.$player = this.$element.querySelector(`.${this.options.classes.player}`);
    this.$playbutton = this.$element.querySelector(`.${this.options.classes.playpause}`);
    this.$mutebutton = this.$element.querySelector(`.${this.options.classes.mutebutton}`);
    this.$songslider = this.$element.querySelector(`.${this.options.classes.songslider}`);
    this.$volumeslider = this.$element.querySelector(`.${this.options.classes.volumeslider}`);
    this.$currentTimeText = this.$element.querySelector(`.${this.options.classes.currentTimeText}`);
    this.$durationText = this.$element.querySelector(`.${this.options.classes.durationTimeText}`);

    // If any button is found, find their icon
    if (this.$playbutton) {
      this.$playbuttonIcon = this.$playbutton.querySelector('.icon');
    }
    if (this.$mutebutton) {
      this.$mutebuttonIcon = this.$mutebutton.querySelector('.icon');
    }

    this.initListeners();
  }

  initListeners() {
    if (this.$playbutton) {
      this.$playbutton.addEventListener('click', this.playpauseClick.bind(this));
    }
    if (this.$mutebutton) {
      this.$mutebutton.addEventListener('click', this.mutebuttonClick.bind(this));
    }

    // Set event listeners for the player...
    // ...when progress or seek
    this.$player.addEventListener('timeupdate', this.timeupdate.bind(this));
    // ...when playback starts
    this.$player.addEventListener('play', this.onplay.bind(this));
    // ...or playback stops or is paused by the user
    this.$player.addEventListener('pause', this.onpause.bind(this));

    // Make an interactive range slider for the songslider element
    if (this.$songslider) {
      this.songslider = new AudioSlider(this.$songslider, {
        changeCallback: this.songpositionChange.bind(this),
      });
    }

    // Make an interactive range slider for the volumeslider element
    if (this.$volumeslider) {
      this.volumeslider = new AudioSlider(this.$volumeslider, {
        changeCallback: this.volumeChange.bind(this),
      });
    }

    // Encounter player initialization from two sides...
    // ...either the audio file is loaded first, then initialize the player on window load
    window.addEventListener('load', this.asyncInit.bind(this));
    // ...or the script file is loaded first, then initialize the player
    // when the audio file is loaded.
    this.$player.addEventListener('canplay', this.asyncInit.bind(this));
  }

  play() {
    this.$player.play();
  }

  pause() {
    this.$player.pause();
  }

  setVolume(volume) {
    this.$player.volume = volume / 100;
  }

  getVolume() {
    const { volume } = this.$player;
    return volume * 100;
  }

  setSongPosition(percent = 0) {
    const { duration } = this.$player;
    this.$player.currentTime = percent * duration / 100;
  }

  getSongPosition() {
    const { currentTime, duration } = this.$player;
    return 100 * currentTime / duration;
  }

  playpauseClick() {
    if (this.$player.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  mutebuttonClick() {
    const $muteIconUse = this.$mutebuttonIcon.querySelector('use');

    if (this.$player.muted) {
      this.$player.muted = false;
      this.volumeslider.enable();
      this.$mutebuttonIcon.classList.remove('icon--muted');
      this.$mutebuttonIcon.classList.add('icon--volume');
      $muteIconUse.setAttribute('xlink:href', '#icon-volume');
    } else {
      this.$player.muted = true;
      this.volumeslider.disable();
      this.$mutebuttonIcon.classList.remove('icon--volume');
      this.$mutebuttonIcon.classList.add('icon--muted');
      $muteIconUse.setAttribute('xlink:href', '#icon-muted');
    }
  }

  songpositionChange() {
    this.setSongPosition(this.songslider.getValue());
  }

  volumeChange() {
    this.setVolume(this.volumeslider.getValue());
  }

  timeupdate() {
    if (this.songslider) {
      this.songslider.setValue(this.getSongPosition());
      this.$currentTimeText.innerHTML = seconds2Time(this.$player.currentTime);
    }
  }

  onplay() {
    const $playIconUse = this.$playbuttonIcon.querySelector('use');
    this.$element.classList.add('audio--playing');
    this.$playbuttonIcon.classList.remove('icon--play');
    this.$playbuttonIcon.classList.add('icon--pause');
    $playIconUse.setAttribute('xlink:href', '#icon-pause');
  }

  onpause() {
    const $playIconUse = this.$playbuttonIcon.querySelector('use');
    this.$element.classList.remove('audio--playing');
    this.$playbuttonIcon.classList.remove('icon--pause');
    this.$playbuttonIcon.classList.add('icon--play');
    $playIconUse.setAttribute('xlink:href', '#icon-play');
  }
}

document.querySelectorAll('.js-audio').forEach(
  $audio => new AudioPlayer($audio),
);

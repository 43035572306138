// Slider component for Audio songtrack and volume sliders
export default class AudioSlider {
  constructor($element, options = {}) {
    this.$element = $element;
    this.options = {
      classes: {
        input: 'audio-slider__input',
        sliderbar: 'audio-slider__slider-bar',
      },
      enableInput: false,
      mouseStart: 0,
      changeCallback: null,
      ...options,
    };

    this.init();
  }

  init() {
    // An html input element (type=range) to preserve semantics on the document.
    this.$input = this.$element.querySelector(`.${this.options.classes.input}`);

    // Register when the range input is changed e.g. by keyboard actions
    this.$input.addEventListener('change', this.inputChanged.bind(this));

    // The actually visible slider bar
    this.$sliderbar = this.$element.querySelector(`.${this.options.classes.sliderbar}`);

    // Register when mouse buttons or mobile screen are pressed OVER the slider bar
    // This is to register that user input is handled from now on.
    this.$sliderbar.addEventListener('mousedown', this.onmousedown.bind(this));
    this.$sliderbar.addEventListener('touchstart', this.onmousedown.bind(this));
    this.$sliderbar.addEventListener('click', this.onmousemove.bind(this));

    // Listen Mouse or touch movements.
    // Listeners are set to window for better use performance
    window.addEventListener('mousemove', this.onmousemove.bind(this));
    window.addEventListener('touchmove', this.onmousemove.bind(this));

    // Listen to the release of mouse buttons or mobile screen
    // to register that user input is not handled anymore.
    window.addEventListener('mouseup', this.onmouseup.bind(this));
    window.addEventListener('touchend', this.onmouseup.bind(this));

    // Set the initial value from the range input element
    this.setValue(this.$input.value);
  }

  updateSliderbar() {
    this.$sliderbar.style.setProperty('--slider-position', `${this.$input.value}%`);
  }

  inputChanged() {
    this.updateSliderbar();

    if (this.options.changeCallback) {
      this.options.changeCallback();
    }
  }

  onmousedown() {
    // Register that user input is handled from now on
    // and set the start position for the percent-value calculation
    this.options.enableInput = true;
    this.options.mouseStart = this.$sliderbar.getBoundingClientRect().left;
  }

  onmousemove(event) {
    if (this.options.enableInput) {
      const { mouseStart } = this.options;
      const { clientWidth } = this.$sliderbar;
      const { clientX } = (event.touches && event.touches[0]) || event;

      // Calculate percentage from mouse position relative to the sliderbar
      const percent = (clientX - mouseStart) / clientWidth;

      // Clip percentage between 0 and 1
      const percentClipped = Math.max(0, Math.min(percent, 1));

      // Set the calculated value to the range input element
      // and call the given callback if any
      this.setValue(100 * percentClipped);
      if (this.options.changeCallback) {
        this.options.changeCallback();
      }
    }
  }

  onmouseup() {
    // Register that user input is ignored from now on
    // to prevent further mouse move or touch events
    this.options.enableInput = false;
  }

  getValue() {
    return this.$input.value;
  }

  setValue(value) {
    this.$input.value = value;
    this.updateSliderbar();
  }

  enable() {
    this.$input.disabled = false;
    this.$element.classList.remove('audio-slider--disabled');
  }

  disable() {
    this.$input.disabled = true;
    this.$element.classList.add('audio-slider--disabled');
  }

  isDisabled() {
    return this.$input.disabled;
  }
}

import { MEDIA_QUERIES } from 'javascripts/constants';
import IconButtonGroupToggle from './IconButtonGroupToggle';

document.querySelectorAll('.icon-button-group__toggle').forEach(($toggle) => {
  const iconButtonGroupToggle = new IconButtonGroupToggle($toggle);

  // Init media query
  const mql = window.matchMedia(MEDIA_QUERIES.m);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isMedium = mq.matches;

    if (isMedium) {
      iconButtonGroupToggle.init();
    } else {
      iconButtonGroupToggle.deinit();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});
